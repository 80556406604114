import { Table } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconMinus, IconPlus } from "@tabler/icons-react";

export const BOMTableRow = (params: any) => {
    const { bomData } = params;
    const [opened, { toggle }] = useDisclosure(false);

    return (
        <>
            <tr key={bomData.bomCode}>
                <td>
                    {
                        bomData.details && bomData.details.length ? (!opened ? (
                            <IconPlus onClick={toggle} />
                        ) : (
                            <IconMinus onClick={toggle} />
                        )
                        ) : null
                    }
                </td>
                <td>{bomData.partnerCode}</td>
                <td>{bomData.modelName}</td>
                <td>{bomData.bomCode}</td>
                <td>{bomData.itemName}</td>
                <td>{bomData.itemType}</td>
                <td>{bomData.unitText}</td>
                <td>{bomData.operationCode}</td>
            </tr>
            {
                bomData.details && bomData.details.length && opened ? (
                    <tr style={{ backgroundColor: "#dbe4ff" }}>
                        <td colSpan={9}>
                            <Table withColumnBorders>
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>품목명</th>
                                        <th>기본/스페어 설정</th>
                                        <th>구분</th>
                                        <th>품목군</th>
                                        <th>단위</th>
                                        <th>1회 사용량</th>
                                        <th>생산 공정</th>
                                        <th>BOM</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        bomData.details && bomData.details.map((detail: any) => (
                                            <tr key={detail.itemCode}>
                                                <td>{detail.code}</td>
                                                <td>{detail.itemName}</td>
                                                <td>{detail.isBasicSpare ? "기본" : "스페어"}</td>
                                                <td>{detail.category}</td>
                                                <td>{detail.itemType}</td>
                                                <td>{detail.unitText}</td>
                                                <td>{detail.usageQuantity}</td>
                                                <td>{detail.productionProcess}</td>
                                                <td>{detail.bom}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                ) : null
            }

        </>
    )
}