import timeUtil from "@/utils/timeUtil";
import styled from "@emotion/styled";
import { Button, Flex, Table } from "@mantine/core";
import { TransportationGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconPrinter } from "@tabler/icons-react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

interface TransportationStatementFormProps {
    data: TransportationGet200ResponseRowsInner;
}

export const TransportationStatementForm = (params: TransportationStatementFormProps) => {
    const { data } = params;

    const num = data?.id; // 입출고 번호
    const date = timeUtil(data?.scheduledAt ?? ''); // 입출고 일자
    const manager = data?.creatorUserCode; // 입출고 담당자
    const partner = data?.partnerCode; // 협력사 명
    const carNum = "123가1234"; // 차량번호
    const direction = data?.direction === "OUTBOUND" ? "발주" : "수주"; // 입출고 방향

    const rows = Array.from({ length: 10 }, (_, index) => {
        const rowData = data || {}; // data.purchaseOrderItems && data.purchaseOrderItems[index] || {};
        return (
            <tr key={index}>
                <td colSpan={2}>{rowData.id || ""}</td>
                <td colSpan={2}>{rowData.status || ""}</td>
                <td>{rowData.status || ""}</td>
                <td>{rowData.partnerCode || ""}</td>
                <td>{rowData.updaterUserCode || ""}</td>
                <td>{rowData.creatorUserCode || ""}</td>
            </tr>
        );
    });

    const today = new Date().getFullYear() + "년 " + (new Date().getMonth() + 1) + "월 " + new Date().getDate() + "일";

    const downloadPDF = () => {
        const input = document.getElementById("pdf-content");
        if (input) {
            html2canvas(input, { scale: 2 }).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const pdf = new jsPDF({
                    orientation: 'portrait',
                    unit: 'mm',
                    format: 'a4'
                });
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
                pdf.save(`${partner}_입출고명세서.pdf`);
            });
        }
    };

    return (
        <>
            <Wrapper>
                <Flex justify={'flex-end'}>
                    <Button
                        onClick={downloadPDF}
                        leftIcon={<IconPrinter />}
                        color="teal"
                        className="no-print"
                    >출력
                    </Button>
                </Flex>
                <div id="pdf-content">
                    <Flex justify={'center'}>
                        <Title>
                            입출고 명세서
                        </Title>
                    </Flex>
                    <Flex justify={'flex-end'} mt="auto">
                        <DateText>
                            작성일자 : {today}
                        </DateText>
                    </Flex>
                    <StyledTable withBorder withColumnBorders w="100%">
                        <thead>
                            <tr>
                                <TitleTh>입출고 번호</TitleTh>
                                <ContentTh colSpan={3}>{num}</ContentTh>
                                <TitleTh>입출고 일자</TitleTh>
                                <ContentTh colSpan={3}>{date}</ContentTh>
                            </tr>
                            <tr>
                                <TitleTh>입출고 담당자</TitleTh>
                                <ContentTh colSpan={3}>{manager}(인)</ContentTh>
                                <TitleTh>협력사 명</TitleTh>
                                <ContentTh colSpan={3}>{partner}</ContentTh>
                            </tr>
                            <tr>
                                <TitleTh>상태</TitleTh>
                                <ContentTh colSpan={3}>{direction}</ContentTh>
                                <TitleTh>차량번호</TitleTh>
                                <ContentTh colSpan={3}>{carNum}</ContentTh>
                            </tr>
                        </thead>
                    </StyledTable>
                    <StyledTable withBorder withColumnBorders w="100%">
                        <thead>
                            <tr>
                                <TitleTh colSpan={2}>품목명</TitleTh>
                                <TitleTh colSpan={2}>Grade</TitleTh>
                                <TitleTh>단위</TitleTh>
                                <TitleTh>로트명</TitleTh>
                                <TitleTh>수량</TitleTh>
                                <TitleTh>창고</TitleTh>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                        <tfoot>
                            <tr>
                                <TitleTh colSpan={2}>비고</TitleTh>
                                <ContentTh colSpan={6}></ContentTh>
                            </tr>
                        </tfoot>
                    </StyledTable>

                </div>
            </Wrapper>
        </>
    )
}

export const Wrapper = styled.div`
    width: 100%;
    padding: 20px;
    overflow: auto;

    @media print {
        width: 210mm;
        height: 297mm;
        margin: 0;
        padding: 20px;
        overflow: hidden;
        -webkit-print-color-adjust: exact;

        .no-print {
            display: none;
        }
    }
`;

export const Title = styled.p`
    font-size: 26px;
    font-weight: bold;
    text-align: center;

    @media print {
        font-size: 24px;
    }
`;

export const DateText = styled.p`
    font-size: 18px;
    font-weight: bold;
    text-align: right;

    @media print {
        font-size: 16px;
    }
`

export const TitleTh = styled.th`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    background-color: #f0f0f0;
    min-width: 10rem;
    height: 3rem;
    vertical-align: middle;

    @media print {
        font-size: 16px;
        background-color: #f0f0f0 !important;
    }
`;

export const ContentTh = styled.th`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    min-width: 10rem;
    height: 3rem;
    vertical-align: middle;
    
    @media print {
        font-size: 16px;
    }
`;

export const StyledTable = styled(Table)`
    thead > tr > th, tfoot > tr > th, tbody > tr > th {
        text-align: center;
        vertical-align: middle;
    }
`;