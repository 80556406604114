import { mutateItems } from "@/api/items/useItemQuery";
import { ItemsForm } from "@/features/item/components/form/itemsStepperForm/ItemsForm";
import { RoutingBomList } from "@/features/item/components/form/itemsStepperForm/RoutingBomList";
import { RoutingsForm } from "@/features/item/components/form/itemsStepperForm/RoutingsForm";
import { RoutingsList } from "@/features/item/components/form/itemsStepperForm/RoutingsList";
import { ItemView } from "@/features/item/components/view/ItemView";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { generateRandomValue } from "@/utils/checkData";
import { handleErrorResponse } from "@/utils/errorMessage";
import { Button, Flex, Space, Stepper } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  ItemsGet200ResponseRowsInner,
  ItemsGet200ResponseRowsInnerItemTypeEnum,
  ItemsGetRequest,
  ItemsOneStepCreatePostRequest,
  ItemsOneStepCreatePostRequestRoutingsInner,
  MasterApiItemsOneStepCreatePostRequest,
  MasterApiItemsPostRequest
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useMemo, useState } from "react";

export interface itemFormProps {
  items: Partial<ItemsGet200ResponseRowsInner>;
  routings: any;
  routingBoms: any;
}

interface ItemsCreateFormProps {
  name?: string;
  autoCompleteOnSubmit?: (values: any) => void;
  itemType?: ItemsGet200ResponseRowsInnerItemTypeEnum | undefined;
}

export interface RoutingFormProps {
  routingRows: Array<ItemsOneStepCreatePostRequestRoutingsInner>;
}

// StepButtons 컴포넌트에 필요한 Props 타입 정의
interface StepButtonsProps {
  active: number;
  nextStep: () => void;
  prevStep: () => void;
  saveForm: () => void;
}

export function ItemsCreateForm() {
  const [active, setActive] = useState(0);

  const { closeModal } = useModal();

  const itemForm = useForm<Partial<ItemsGetRequest>>({
    initialValues: {
      code: "",                                         // Grade
      itemType: "MATERIAL",   // 품목군
      name: "",                                         // 품목명
      itemCategoryName: undefined,                      // 품목 카테고리 (분류)
      abstractItemCode: undefined,                      // 형태
      spec: "",
      height: "",
      depth: "",
      width: "",
      texture: "",
      standardUph: 0,
      unitText: "kg",
      usesLotName: false,
      usesLotExpiration: false,
      additional: {},
    },
  });

  const routingForm = useForm<Partial<RoutingFormProps>>({
    initialValues: {
      routingRows: [
        {
          seq: 0, // 라우팅순서
          operationCode: "", // 공정코드
          code: generateRandomValue(), // 라우팅코드
          routingType: "NORMAL", // 라우팅유형 (라우팅 타입)
          consumeType: "", // 소모 시점
          routingBoms: [
            {
              itemCode: "",
              quantity: "",
            },
          ], // 라우팅 BOM
        },
      ]
    },
  },
  );

  const queryClient = useQueryClient();

  const { mutate: createOneStepMutate } = useMutation(
    (params: MasterApiItemsOneStepCreatePostRequest) =>
      mutateItems.oneStep(params).mutationFn(params as MasterApiItemsOneStepCreatePostRequest | any), {
    onSuccess: () => {
      queryClient.invalidateQueries(['items']);
    }
  }
  );
  const { mutate: createMutate } = useMutation(
    (params: MasterApiItemsPostRequest) =>
      mutateItems.create(params).mutationFn(params as MasterApiItemsPostRequest | any), {
    onSuccess: () => {
      queryClient.invalidateQueries(['items']);
    }
  }
  );

  const isValidValue = (value: any) => value !== undefined && value !== null && value !== '';

  // 유효성 검사 함수
  const isValidStep = (step: number) => {
    if (step === 0) {
      // active가 0일 때: Grade, 품목명, 품목군 유효성 검사
      const { code, name, itemType } = itemForm.values;
      return isValidValue(code) && isValidValue(name) && isValidValue(itemType);
    }

    if (step === 1) {
      // active가 1일 때: 라우팅 정보 유효성 검사
      return (
        routingForm.values.routingRows &&
        routingForm.values.routingRows.every((row) =>
          row &&
          isValidValue(row.seq) &&
          isValidValue(row.operationCode) &&
          isValidValue(row.code) &&
          isValidValue(row.routingType) &&
          isValidValue(row.consumeType)
        )
      );
    }

    if (step === 2) {
      // active가 2일 때: 라우팅 BOM 유효성 검사
      return (
        routingForm.values.routingRows &&
        routingForm.values.routingRows.every((row) =>
          row &&
          row.routingBoms &&
          row.routingBoms.every((bom) =>
            (!isValidValue(bom.itemCode) && !isValidValue(bom.quantity)) ||
            (isValidValue(bom.itemCode) && isValidValue(bom.quantity))
          )
        )
      );
    }

    // 기본적으로 유효하다고 가정
    return true;
  };


  const isMaterial =
    itemForm.values.itemType === "MATERIAL" ||
    itemForm.values.itemType === "SUBSIDIARY";

  const stepCount = isMaterial ? 2 : 4;

  const StepButtons = React.memo(({ active, nextStep, prevStep, saveForm }: StepButtonsProps) => {
    const isLastStep = active === stepCount - 1;
    const isFirstStep = active === 0;

    return (
      <Flex w="100%" py="sm" gap="sm">
        <Button
          w="100%"
          variant="outline"
          onClick={prevStep}
          disabled={isFirstStep}
        >
          이전
        </Button>

        {!isLastStep && (
          <Button
            w="100%"
            variant="outline"
            onClick={nextStep}
            disabled={!isValidStep(active)}
          >
            다음
          </Button>
        )}

        {isLastStep && <Button w="100%" onClick={saveForm}>등록</Button>}
      </Flex>
    );
  });


  const steps = useMemo(() => {
    return isMaterial
      ? [
        {
          label: "기본정보 등록",
          description: "기본 정보를 등록합니다.",
          content: <ItemsForm formData={itemForm} />,
        },
        {
          label: "확인",
          description: "최종적으로 생성될 품목을 미리 확인합니다.",
          content: (
            <ItemView itemData={itemForm.values} />
          ),
        },
      ]
      : [
        {
          label: "기본정보 등록",
          description: "기본 정보를 등록합니다.",
          content: <ItemsForm formData={itemForm} />,
        },
        {
          label: "라우팅정보 등록",
          description: "라우팅 정보를 등록합니다.",
          content: (
            <>
              <ItemView itemData={itemForm.values} />
              <Space h="sm" />
              <RoutingsForm formData={routingForm} />
            </>
          ),
        },
        {
          label: "라우팅별 BOM 등록",
          description: "라우팅별 BOM을 등록합니다.",
          content: (
            <>
              <ItemView itemData={itemForm.values} />
              <Space h="sm" />
              <RoutingsList formData={routingForm} />
            </>
          ),
        },
        {
          label: "확인",
          description: "최종적으로 생성될 품목을 미리 확인합니다.",
          content: (
            <>
              <ItemView itemData={itemForm.values} />
              <RoutingBomList formData={routingForm.values.routingRows} />
            </>
          ),
        },
      ];
  }, [itemForm, routingForm, isMaterial]);

  const nextStep = useCallback(() => {
    setActive((prev) => Math.min(prev + 1, stepCount - 1));
  }, [stepCount]);

  const prevStep = useCallback(() => {
    setActive((prev) => Math.max(prev - 1, 0));
  }, []);

  const saveForm = () => {
    const requestData = {
      code: itemForm.values.code,
      itemType: itemForm.values.itemType,
      name: itemForm.values.name,
      itemCategoryName: itemForm.values.itemCategoryName || undefined,
      abstractItemCode: itemForm.values.abstractItemCode || undefined,
      usesLotName: itemForm.values.usesLotName || false,
      usesLotExpiration: itemForm.values.usesLotExpiration || false,
      spec: itemForm.values.spec || undefined,
      standardUph: itemForm.values.standardUph || undefined,
      width: itemForm.values.width || undefined,
      height: itemForm.values.height || undefined,
      depth: itemForm.values.depth || undefined,
      texture: itemForm.values.texture || undefined,
      unitText: itemForm.values.unitText || undefined,
      ...(!isMaterial ? {
        routings: routingForm.values.routingRows && routingForm.values.routingRows.map((row) => {
          return {
            code: row.code || undefined,
            operationCode: row.operationCode || undefined,
            routingType: row.routingType || undefined,
            consumeType: row.consumeType || undefined,
            seq: Number(row.seq) || undefined,
            routingBoms: row.routingBoms && row.routingBoms.map((bom) => ({
              quantity: bom.quantity || undefined,
              itemCode: bom.itemCode || undefined,
            })),
          }
        })
      } : {}),
    };

    // 품목군이 '원/부자재'인 경우
    if (isMaterial) {
      createMutate(
        {
          itemsGetRequest: requestData as ItemsGetRequest,
        },
        {
          onSuccess: (res) => {
            onSuccess(res);
          },
          onError: (error: any) => {
            onError(error);
          }
        }
      );
    } else {
      // 품목군이 '제품'인 경우
      createOneStepMutate(
        { itemsOneStepCreatePostRequest: requestData as ItemsOneStepCreatePostRequest },
        {
          onSuccess: (res) => {
            onSuccess(res);
          },
          onError: (error: any) => {
            onError(error);
          }
        }
      );
    }
  };

  const onSuccess = (res: any) => {
    if (res.status === 200) {
      customAlert(
        "품목 생성에 성공하였습니다.",
        "생성 성공",
        "green"
      )
    } else {
      customAlert(
        "품목 생성에 실패하였습니다.",
        "생성 실패",
        "red"
      )
    }
    itemForm.reset();
    if (!isMaterial) {
      routingForm.reset();
    }
    closeModal(() => {
      setActive(0);
    });
  }

  const onError = (error: Error) => {
    customAlert(handleErrorResponse({ error, errorContext: 'Grade' }), '품목 생성 실패', 'red')
  }

  return (
    <Flex w="75rem">
      <Stepper w="100%" active={active} breakpoint="sm">
        {steps.map((step, index) => (
          <Stepper.Step
            key={index}
            label={step.label}
            description={step.description}
          >
            <StepButtons
              active={active}
              nextStep={nextStep}
              prevStep={prevStep}
              saveForm={saveForm}
            />
            {step.content}
          </Stepper.Step>
        ))}
      </Stepper>
    </Flex>
  );
}

