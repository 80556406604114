import useLedgersAllQuery from "@/api/ledgers/useGetLedgersAllQuery";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { setWmsSatus, wmsStatusColor } from "@/utils/wmsStatus";
import { Badge } from "@mantine/core";
import dayjs from "dayjs";
import { MRT_ColumnDef, MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface LotLedgeTabsProps {
    lotId: number | undefined;
}

interface Row {
    id: number;
    itemCode: string;
    itemData: {
        name: string;
        unit_text: string;
    };
    locationCode: string;
    locationData: {
        name: string
    };
    lotId: number;
    lotData: {
        name?: string;
        expiration?: string;
    };
    quantity: string;
    userId: number;
    userCode: string;
    createdAt: string;
    additional: {
        purchaseItemId: number;
        wmsAction: string;
        workAction: string;
    }
}

export const LotLedgeTabs = (params: LotLedgeTabsProps) => {
    const { lotId } = params;

    const { data } = useLedgersAllQuery({
        query: {
            $and: [
                { lotId: { $eq: lotId } }
            ]
        },
    })

    const rows: Row[] = data?.data?.rows ?? [];
    const { openModal } = useModal();
    const { t } = useTranslation();

    const columns = useMemo<MRT_ColumnDef<Row>[]>(
        () => [
            {
                accessorFn: (row) => row.id,
                accessorKey: 'id',
                header: '번호',
                size: 80,
                Cell: (rows) => {
                    return (
                        <div style={{ textAlign: 'right' }}>{rows.row._valuesCache.id}</div>
                    )
                }
            },
            {
                accessorFn: (row) =>
                    <DetailLink
                        onClick={() => {
                            openModal(
                                <ItemsDetailForm itemCode={row.itemCode} />,
                                null,
                                "품목 상세"
                            )
                        }}
                        justify="flex-start"
                    >
                        {row.itemData?.name}
                    </DetailLink>,
                accessorKey: 'itemCode',
                header: 'Grade',
                size: 80,
            },
            {
                accessorFn: (row) =>
                    <DetailLink
                        onClick={() => {
                            openModal(
                                <LocationDetailForm locationCode={row.locationCode} />,
                                null,
                                ""
                            )
                        }}
                        justify="flex-start"
                    >
                        {row.locationData?.name}
                    </DetailLink>,
                accessorKey: 'locationName',
                header: '위치',
                size: 80,
            },
            {
                accessorFn: (row) =>
                    row.additional.wmsAction ?
                        <Badge color={wmsStatusColor[row.additional.wmsAction as keyof typeof wmsStatusColor]}>
                            {setWmsSatus(row.additional.wmsAction)}
                        </Badge>
                        :
                        row.additional.workAction ?
                            <Badge color={wmsStatusColor[row.additional.workAction as keyof typeof wmsStatusColor]}>
                                {setWmsSatus(row.additional.workAction)}
                            </Badge>
                            :
                            ''
                ,
                accessorKey: 'wmsAction',
                header: '상세',
                size: 80,
            },
            {
                accessorFn: (row) => `${row.quantity} ${row.itemData?.unit_text}`,
                accessorKey: 'quantity',
                header: '재고 변화량',
                size: 80,
                Cell: ({ row }) => <div style={{ textAlign: 'right' }}>{row._valuesCache.quantity}</div>,
            },
            {
                accessorFn: (row) => dayjs(row.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                accessorKey: 'createdAt',
                header: '생성일',
                size: 200,
            }

        ],
        [],
    );

    const table = useMantineReactTable({
        columns,
        data: rows,
        editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
        enableEditing: false,
        enableToolbarInternalActions: false, // 우측상단 아이콘 안보이게
        enablePagination: false,
    });

    return <MantineReactTable table={table} />;
}