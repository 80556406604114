import { items, mutateItems } from "@/api/items/useItemQuery";
import { itemsHeader } from "@/constants/columnHeader";
import { itemTypeColor } from "@/constants/items";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { ItemsUpdateForm } from "@/features/item/components/form/ItemsUpdateForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import customAlert from "@/features/ui/alert/alert";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { useSub } from "@/hooks";
import useQueryString from "@/hooks/useQueryString";
import useUpdateUrlParams from "@/hooks/useUpdateUrlParams";
import { theme } from "@/styles/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import {
  Badge,
  Button,
  Flex,
  Pagination,
  useMantineColorScheme
} from "@mantine/core";
import { ItemsGet200ResponseRowsInner, ItemsGet200ResponseRowsInnerItemTypeEnum, MasterApiItemsItemIdDeleteRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconSearch, IconTrash } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import type { Column, SortColumn } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

interface Row {
  id: number;
  name: string;
  itemType: string;
  code: string;
  usesLotName?: boolean;
  usesLotExpiration?: boolean;
}

interface ColorThemeProps {
  isDarkMode: boolean;
}

export const BomsTable = () => {

  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

  const { t } = useTranslation();
  // const [rows, setRows] = useState<any>(createRows);
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const isDarkMode: boolean = colorScheme === "light";

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<any> => new Set()
  );
  const [activePage, setPage] = useState(initialQueryString.page);
  const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
  const [sort, setSort] = useState(initialQueryString.sort);
  const [search, setSearch] = useState<SearchProps>({
    search: initialQueryString.search,
    searchFields: initialQueryString.searchFields,
    pageSize: initialQueryString.pageSize,
  });

  const updateUrlParams = useUpdateUrlParams();

  const searchFieldsHeader = itemsHeader.filter(
    (item) => item.category === "text" && !item.isEnum
  );

  const { data: item, refetch } = useQuery(items.get({
    query: {
      ...query,
      $and: [
        {
          itemType: [
            ItemsGet200ResponseRowsInnerItemTypeEnum.PRODUCT,
            ItemsGet200ResponseRowsInnerItemTypeEnum.SEMI_PRODUCT
          ]
        },
      ]
    },
    search: search.search,
    searchFields: search.searchFields.length
      ? search.searchFields
      : searchFieldsHeader.map((item) => item.value),
    page: activePage,
    pageSize: Number(search.pageSize),
    sort: sort.length ? sort.join(",") : "-id"
  }));

  // 새로운 데이터가 로드될 때, 현재 페이지가 유효하지 않으면 1페이지로 설정
  useEffect(() => {
    if (item && activePage > (item?.data?.totalPages ?? 0)) {
      setPage(1);
      setQueryString((prev) => ({
        ...prev,
        page: 1,
      }));
    }
  }, [item, activePage, setQueryString]);

  // 페이지 Query String으로 공정 이동
  useEffect(() => {
    updateUrlParams({
      page: activePage,
      pageSize: search.pageSize,
      search: search.search,
      searchFields: search.searchFields,
      query: JSON.stringify(query),
      sort: sort
    }, queryDefaultValues);
  }, [activePage, search, query, sort, updateUrlParams]);

  const rows: readonly ItemsGet200ResponseRowsInner[] = item?.data.rows ?? [];
  const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());
  const { mutate: deleteMutate } = useMutation(
    (params: MasterApiItemsItemIdDeleteRequest) =>
      mutateItems.delete(params).mutationFn(params as MasterApiItemsItemIdDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
      }
    }
  );

  const { openModal } = useModal();
  const columns: readonly Column<ItemsGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "name",
      name: "품목명",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const detailItemAction = () => {
          openModal(
            <ItemsDetailForm itemCode={formatterProps?.row.code} />,
            null,
            "품목 상세"
          );
        };

        return (
          <DetailLink onClick={detailItemAction}>{formatterProps.row.name}</DetailLink>
        );
      },
    },
    {
      key: "itemType",
      name: "품목군",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => { return <Badge size="lg" color={itemTypeColor[row.itemType]}>{t(row.itemType)}</Badge> },
    },
    {
      key: "code",
      name: "Grade",
      sortable: true,
      resizable: true,
    },
    // {
    //   key: "itemCategoryName",
    //   name: "Color",
    //   sortable: true,
    //   resizable: true,
    //   formatter: (formatterProps) => {
    //     return (
    //       <DetailLink onClick={() => {
    //         openModal(
    //           <ItemCategoryDetailForm
    //             ItemCategoryName={formatterProps.row.itemCategoryName}
    //           />,
    //           null,
    //           ""
    //         );
    //       }}>
    //         {formatterProps.row.itemCategoryName}
    //       </DetailLink>
    //     );
    //   },
    // },
    // {
    //   key: "abstractItemCode",
    //   name: "형태",
    //   sortable: true,
    //   resizable: true,
    //   formatter: (formatterProps) => {
    //     return (
    //       <DetailLink
    //         onClick={() => {
    //           openModal(
    //             <AbstractItemDetailForm
    //               AbstractItemCode={formatterProps.row.abstractItemCode}
    //             />,
    //             null,
    //             "",
    //             true
    //           );
    //         }}
    //       >
    //         {formatterProps.row.abstractItemCode}
    //       </DetailLink>
    //     );
    //   }
    // },
    // {
    //   key: "spec",
    //   name: "규격",
    //   width: 100,
    //   sortable: true,
    //   resizable: true,
    // },
    // {
    //   key: "height",
    //   name: "높이",
    //   width: 80,
    //   sortable: true,
    //   resizable: true,
    //   cellClass: css`
    //     justify-content: flex-end;
    //   `,
    // },
    // {
    //   key: "depth",
    //   name: "깊이",
    //   width: 80,
    //   sortable: true,
    //   resizable: true,
    //   cellClass: css`
    //     justify-content: flex-end;
    //   `,
    // },
    // {
    //   key: "width",
    //   name: "폭",
    //   width: 80,
    //   sortable: true,
    //   resizable: true,
    //   cellClass: css`
    //     justify-content: flex-end;
    //   `,
    // },
    // {
    //   key: "texture",
    //   name: "재질",
    //   width: 80,
    //   sortable: true,
    //   resizable: true,
    // },
    // {
    //   key: "standardUph",
    //   name: "기준 UPH",
    //   width: 80,
    //   sortable: true,
    //   resizable: true,
    // },
    // {
    //   key: "unitText",
    //   name: "단위",
    //   width: 80,
    //   sortable: true,
    //   resizable: true,
    // },
    // {
    //   key: "lot",
    //   name: "LOT 관리 기준",
    //   width: 170,
    //   formatter: (row) => {
    //     return (
    //       <>
    //         <Checkbox
    //           value="usesLotName"
    //           label="이름"
    //           mr={12}
    //           disabled
    //           checked={row?.row.usesLotName}
    //         />
    //         <Checkbox
    //           value="useLotExpiration"
    //           label="유효기한"
    //           disabled
    //           checked={row?.row.usesLotExpiration}
    //         />
    //       </>
    //     );
    //   },
    // },
    {
      key: "options",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const detailItemAction = () => {
          openModal(
            <ItemsDetailForm itemCode={formatterProps?.row.code} />,
            null,
            "품목 상세"
          );
        };
        const updateItemAction = async () => {
          openModal(
            <ItemsUpdateForm formatterProps={formatterProps?.row} />,
            null,
            "품목 수정",
            true
          );
        };

        return (
          <OptionBox>
            <OptionBtn
              variant="subtle"
              onClick={detailItemAction}
              leftIcon={<IconSearch />}
              color="blue"
            ></OptionBtn>
            <OptionBtn
              variant="subtle"
              onClick={updateItemAction}
              leftIcon={<IconEdit />}
              color="yellow"
            ></OptionBtn>
            <OptionBtn
              variant="subtle"
              leftIcon={<IconTrash />}
              color="red"
              onClick={() => {
                window.confirm("정말로 삭제하시겠습니까?") &&
                  deleteMutate(
                    {
                      itemId: formatterProps.row.id as number,
                    },
                    {
                      onSuccess: (res) => {
                        if (res.status === 200) {
                          customAlert(
                            "품목 삭제에 성공하였습니다.",
                            "삭제 성공",
                            "green"
                          );
                        } else {
                          customAlert(
                            "품목 삭제에 실패하였습니다.",
                            "삭제 실패",
                            "red"
                          );
                        }
                      },
                    }
                  );
              }}
            ></OptionBtn>
          </OptionBox>
        );
      },
    },
  ];

  useSub("deleteSelectedItems", () => {
    const rows = Array.from(selectedRowsRef.current);
    if (!rows.length) return;
    const deleteRows: number[] = Array.from(rows[rows.length - 1].values());
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      deleteRows.forEach((row: number) => {
        deleteMutate({
          itemId: row,
        });
      });
      selectedRowsRef.current = new Set();
    }
  });

  return (
    <ItemTableWrapper>
      <GridWrapper isDarkMode={isDarkMode}>
        <TableWrapper>
          <RetrieveWrapper>
            <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
              <CustomFilter filterType={itemsHeader} setQuery={setQuery} query={query} />
              <CustomSorter sorterType={itemsHeader} setSort={setSort} sort={sort} />
            </Flex>

            <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} search={search} />
          </RetrieveWrapper>
          <DataGrid
            columns={columns ?? []}
            rows={rows}
            rowHeight={40}
            rowKeyGetter={(row: Row) => row.id}
            sortColumns={sortColumns}
            selectedRows={selectedRows}
            onSelectedRowsChange={(e) => {
              setSelectedRows(e);
              const newSelectedRows = new Set(selectedRowsRef.current);
              newSelectedRows.add(e);
              selectedRowsRef.current = newSelectedRows;
            }}
          />
        </TableWrapper>
        <PaginationWrapper>
          <Pagination
            onChange={setPage}
            value={activePage}
            total={item?.data?.totalPages ?? 0}
            size="lg"
            radius="sm"
          />
        </PaginationWrapper>
      </GridWrapper>
    </ItemTableWrapper>
  );
};

const ItemTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;
const GridWrapper = styled.div<ColorThemeProps>`
  & *[role="grid"] {
    height: inherit;
    --rdg-background-color: ${(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-header-background-color: ${(props) =>
    props.isDarkMode ? "white" : "none"};
    --rdg-color: ${(props) => (props.isDarkMode ? "black" : "white")};
    --rdg-row-hover-background-color: ${(props) =>
    props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;
const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;
const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const RetrieveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
`;
