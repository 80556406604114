import { MasterInstance } from "@/instance/axios";
import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { MasterApiSitesFindPostRequest, MasterApiSitesGetRequest, MasterApiSitesPostRequest, MasterApiSitesSiteIdDeleteRequest, MasterApiSitesSiteIdGetRequest, MasterApiSitesSiteIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const sites = createQueryKeys('sites', {
    all: null,
    get: (params: MasterApiSitesGetRequest) => {
        // const queryParams = { ...params };
        const queryParams = { ...params, query: JSON.stringify({ "$and": params?.query }), sort: params.sort?.length ? params.sort : "-id", }; // 사업장 조회 시 기본 정렬 표시

        return {
            queryKey: [queryParams, JSON.stringify(queryParams)],
            queryFn: () => MasterInstance.sitesGet(queryParams),
        }
    },
    // get: (params: MasterApiSitesGetRequest) => {
    //     const query = { ...params }; // params 객체를 복사합니다.

    //     const isEmpty = Object.keys(query).length > 0;
    //     if (isEmpty && Array.isArray(params.query.$and) && params.query.$and.length > 0) {
    //         query["query"] = JSON.stringify(params.query);
    //     }
    //     // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
    //     if (isEmpty && params.search) {
    //         query["search"] = params.search;
    //         query["searchFields"] = params.searchFields;
    //     }
    //     return {
    //         queryKey: [query, JSON.stringify(query.query)],
    //         queryFn: () => MasterInstance.sitesGet(query),
    //     }
    // },
    detail: (params: MasterApiSitesSiteIdGetRequest) => ({
        queryKey: [params.siteId],
        queryFn: () => MasterInstance.sitesSiteIdGet(params)
    }),
    find: (params: MasterApiSitesFindPostRequest) => {
        const query = { ...params }; // params 객체를 복사합니다.

        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => MasterInstance.sitesFindPost(query),
        }
    },
})

export const mutateSites = createMutationKeys('sites', {
    create: (params: MasterApiSitesPostRequest) => ({
        mutationKey: [params.sitesGetRequest],
        mutationFn: () => MasterInstance.sitesPost(params),
    }),
    update: (params: MasterApiSitesSiteIdPutRequest) => ({
        mutationKey: [params.siteId, params.sitesGetRequest],
        mutationFn: () => MasterInstance.sitesSiteIdPut(params)
    }),
    delete: (params: MasterApiSitesSiteIdDeleteRequest) => ({
        mutationKey: [params.siteId],
        mutationFn: () => MasterInstance.sitesSiteIdDelete(params)
    }),

});

export const sitesQueryKeys = mergeQueryKeys(sites, mutateSites);