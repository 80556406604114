import { AuthorityCreateForm } from "@/features/authority/form/createForm";
import { AuthorityUpdateForm } from "@/features/authority/form/updateForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import customAlert from "@/features/ui/alert/alert";
import styled from "@emotion/styled";
import { Button, Checkbox, Flex } from "@mantine/core";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import type { Column } from "react-data-grid";
import DataGrid from "react-data-grid";

interface CommonCode {
    commonCode: string;
    codeName: string;
}

interface CategoryDetail {
    categoryCode: string;
    categoryName: string;
    systemUse: boolean;
    active: boolean;
}

interface CategoryDetails {
    [key: string]: CategoryDetail[];  // 각 권한(CMM001, CMM002 등)가 key로 사용됨
}

interface AuthorityPageMockupDataType {
    commonCodes: CommonCode[];  // 권한 목록
    categoryDetails: CategoryDetails;  // 각 권한에 대한 세부 항목
}

const AuthorityWrap = {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    ActionHeader: ActionHeader,
    ActionButton: Button,
    Wrapper: Wrapper,
    CreateForm: AuthorityCreateForm,
    UpdateForm: AuthorityUpdateForm,
    // CreateForm: CommonCodeCreateForm,
    // UpdateForm: CommonCodeUpdateForm,
    // PartCreateForm: CommonPartCodeCreateForm,
    // PartUpdateForm: CommonPartCodeUpdateForm
};

export const AuthorityPage = () => {
    const { openModal } = useModal();

    const [auth, setAuth] = useState<number>(0);  // 초기값을 빈 문자열로 설정
    const [details, setDetails] = useState<any[]>([]);  // 우측 detail 데이터를 저장하는 상태

    // 선택된 권한에 따른 필터링된 detail 데이터를 업데이트
    useEffect(() => {
        if (auth) {
            setDetails(flattenMenuList(AuthorityPageMockupData.find((item) => item.authId === auth)?.authorityMenuList ?? []));
        } else {
            setDetails([]);  // auth가 0이면 details는 빈 배열로 설정
        }
    }, [auth]);

    // 좌측 데이터의 총 갯수 및 페이지 계산
    const parentPaginatedRows = AuthorityPageMockupData.map((item) => ({ authorityName: item.authorityName, authId: item.authId }));

    const parentColumns: readonly Column<any>[] = [
        {
            key: "authorityName",
            name: "권한명",
            sortable: true,
            resizable: true,
        },
        {
            key: "options",
            name: "액션",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                return (
                    <OptionBox>
                        <OptionBtn
                            variant="subtle"
                            leftIcon={<IconEdit />}
                            color="yellow"
                            onClick={() => {
                                openModal(
                                    <AuthorityWrap.UpdateForm
                                        formatterProps={formatterProps.row}
                                    />,
                                    {},
                                    "권한 수정",
                                    true
                                );
                            }}
                        ></OptionBtn>
                        <OptionBtn
                            variant="subtle"
                            leftIcon={<IconTrash />}
                            color="red"
                            onClick={() => {
                                window.confirm("정말로 삭제하시겠습니까?") &&
                                    customAlert(
                                        "권한 삭제에 실패하였습니다.",
                                        "삭제 실패",
                                        "red"
                                    );
                            }}
                        ></OptionBtn>
                    </OptionBox>
                );
            }
        }
    ];

    const childColumns: readonly Column<any>[] = [
        {
            key: "parentLabel",
            name: "상위 메뉴",
            sortable: true,
            resizable: true,
        },
        {
            key: "label",
            name: "페이지명",
            sortable: true,
            resizable: true,
        },
        {
            key: "menuUse",
            name: "허용 여부",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return <Checkbox
                    checked={row.menuUse}
                    onChange={(event) => handleCheckboxChange(event, row)}
                />
            }
        },
    ];

    const handleCheckboxChange = (event: any, row: any) => {
        const { checked } = event.target;
        setDetails((prevDetails) =>
            prevDetails.map((detail) =>
                detail.value === row.value ? { ...detail, menuUse: checked } : detail
            )
        );
    };

    return (
        <AuthorityWrap.Wrapper>
            <AuthorityWrap.ActionHeader>
                <AuthorityWrap.ActionButton
                    leftIcon={<IconPlus />}
                    onClick={() => openModal(<AuthorityWrap.CreateForm />, null, "권한 추가", true)}
                >
                    권한 추가
                </AuthorityWrap.ActionButton>
                <AuthorityWrap.ActionButton
                    color="teal"
                    rightIcon={<IconEdit />}
                    onClick={() => alert('수정')}
                >
                    페이지 권한 수정
                </AuthorityWrap.ActionButton>
            </AuthorityWrap.ActionHeader>

            <Flex w="100%" h="100%" gap="sm">
                {/* 왼쪽 권한 테이블 */}
                <GridWrapper>
                    <DataGrid
                        columns={parentColumns ?? []}
                        rows={parentPaginatedRows ?? []}
                        rowHeight={40}
                        rowKeyGetter={(row) => row.authId}
                        onRowClick={(row) => setAuth(row.authId)}  // 코드 선택 시 상태 업데이트
                    />
                </GridWrapper>

                {/* 우측 구분코드 테이블 (선택된 권한에 따라) */}
                <GridWrapper>
                    {auth ? (
                        <Flex direction="column" gap="sm" style={{ border: "1px solid black" }} p="md">
                            <DataGrid
                                columns={childColumns ?? []}
                                rows={details ?? []}  // 선택된 코드의 detail 데이터
                                rowHeight={40}
                                rowKeyGetter={(row) => row.categoryCode}
                            />
                        </Flex>
                    ) : (
                        <p>권한을 선택하세요.</p>  // 코드가 선택되지 않은 경우
                    )}
                </GridWrapper>
            </Flex>
        </AuthorityWrap.Wrapper >
    );
}

const flattenMenuList = (authorityMenuList: any[]) => {
    const flatList: any[] = [];

    // 재귀적으로 children을 순회하여 평탄화하는 함수
    function flatten(menu: any, parentLabel = null) {
        if (menu.children && menu.children.length > 0) {
            menu.children.forEach((child: any) =>
                flatten(child, menu.label) // 상위 메뉴의 label을 전달
            );
        } else {
            // children이 없는 경우 메뉴를 flatList에 추가
            flatList.push({
                value: menu.value,
                label: menu.label,
                menuOrder: menu.menuOrder,
                menuUse: menu.menuUse,
                menuParent: menu.menuParent,
                parentLabel: parentLabel,
            });
        }
    }

    // 모든 메뉴에 대해 flatten 함수 실행
    authorityMenuList.forEach((menu) => flatten(menu));
    return flatList;
}

const AuthorityPageMockupData = [
    {
        authId: 1,
        authorityName: "MASTER",
        authorityMenuList: [
            {
                value: 1,
                label: "시스템관리",
                menuOrder: 1,
                menuUse: true,
                menuParent: null,
                children: [
                    { value: 2, label: "System Access Log", menuOrder: 1, menuUse: true, menuParent: 1, },
                    { value: 3, label: "로그정보 API 연동", menuOrder: 2, menuUse: true, menuParent: 1, },
                    { value: 4, label: "시스템 파라미터 관리", menuOrder: 3, menuUse: true, menuParent: 1, }
                ],
            },
            {
                value: 5,
                label: "기준정보",
                menuOrder: 2,
                menuUse: true,
                menuParent: null,
                children: [
                    { value: 6, label: "공통코드", menuOrder: 1, menuUse: true, menuParent: 5, },
                    { value: 7, label: "권한그룹", menuOrder: 2, menuUse: true, menuParent: 5, },
                    { value: 8, label: "부서코드", menuOrder: 3, menuUse: true, menuParent: 5, },
                    { value: 9, label: "품목", menuOrder: 4, menuUse: true, menuParent: 5, },
                    { value: 10, label: "설비", menuOrder: 5, menuUse: true, menuParent: 5, },
                    { value: 11, label: "불량", menuOrder: 6, menuUse: true, menuParent: 5 },
                    { value: 12, label: "공정", menuOrder: 7, menuUse: true, menuParent: 5, },
                    { value: 13, label: "라우팅", menuOrder: 8, menuUse: true, menuParent: 5, },
                    { value: 14, label: "사용자", menuOrder: 9, menuUse: true, menuParent: 5, },
                    { value: 15, label: "사업장", menuOrder: 10, menuUse: true, menuParent: 5, },
                    { value: 16, label: "로케이션", menuOrder: 11, menuUse: true, menuParent: 5, },
                    { value: 17, label: "외주처", menuOrder: 12, menuUse: true, menuParent: 5, },
                    { value: 18, label: "ALC 모델코드", menuOrder: 13, menuUse: true, menuParent: 5, },
                    { value: 19, label: "금형", menuOrder: 14, menuUse: true, menuParent: 5, },
                    { value: 20, label: "금형재고", menuOrder: 15, menuUse: true, menuParent: 5, },
                    { value: 21, label: "형태", menuOrder: 16, menuUse: true, menuParent: 5, },
                    { value: 22, label: "Color", menuOrder: 17, menuUse: true, menuParent: 5 },
                    { value: 23, label: "협력사", menuOrder: 18, menuUse: true, menuParent: 5 },
                    { value: 24, label: "비가동사유", menuOrder: 19, menuUse: true, menuParent: 5 },
                    { value: 25, label: "BOM등록", menuOrder: 20, menuUse: true, menuParent: 5 },
                    { value: 26, label: "BOM조회", menuOrder: 21, menuUse: true, menuParent: 5 },
                    { value: 27, label: "BOM전개조회", menuOrder: 22, menuUse: true, menuParent: 5 },
                    { value: 28, label: "문서관리", menuOrder: 23, menuUse: true, menuParent: 5 },
                ]
            },
            {
                value: 29,
                label: "생산관리",
                menuOrder: 3,
                menuUse: true,
                menuParent: null,
                children: [
                    { value: 30, label: "설비별 생산현황", menuOrder: 1, menuUse: true, menuParent: 29, },
                    { value: 31, label: "생산계획별 생산현황", menuOrder: 2, menuUse: true, menuParent: 29, },
                    { value: 32, label: "작업지시", menuOrder: 3, menuUse: true, menuParent: 29, },
                    { value: 33, label: "작업일지", menuOrder: 4, menuUse: true, menuParent: 29, },
                    { value: 34, label: "작업완료", menuOrder: 5, menuUse: true, menuParent: 29, },
                    { value: 35, label: "정지유형별 통계", menuOrder: 6, menuUse: true, menuParent: 29, },
                ]
            },
            {
                value: 36,
                label: "재고관리",
                menuOrder: 4,
                menuUse: true,
                menuParent: null,
                children: [
                    { value: 37, label: "재고현황", menuOrder: 1, menuUse: true, menuParent: 36, },
                    { value: 38, label: "재고 입출고 내역", menuOrder: 2, menuUse: true, menuParent: 36, },
                    { value: 39, label: "재공품 재고", menuOrder: 3, menuUse: true, menuParent: 36, },
                    { value: 40, label: "WMS 로그", menuOrder: 4, menuUse: true, menuParent: 36, },
                    { value: 41, label: "재고수불이력", menuOrder: 5, menuUse: true, menuParent: 36, },
                    { value: 42, label: "안전재고부족조회", menuOrder: 6, menuUse: true, menuParent: 36, },
                    { value: 43, label: "재고수명조회", menuOrder: 7, menuUse: true, menuParent: 36, },
                ]
            },
            {
                value: 44,
                label: "입출고관리",
                menuOrder: 5,
                menuUse: true,
                menuParent: null,
                children: [
                    { value: 45, label: "입고의뢰", menuOrder: 1, menuUse: true, menuParent: 44, },
                    { value: 46, label: "입고등록", menuOrder: 2, menuUse: true, menuParent: 44, },
                    { value: 47, label: "입고현황", menuOrder: 3, menuUse: true, menuParent: 44, },
                ]
            },
            {
                value: 48,
                label: "영업관리",
                menuOrder: 6,
                menuUse: true,
                menuParent: null,
                children: [
                    { value: 49, label: "수주현황", menuOrder: 1, menuUse: true, menuParent: 48, },
                    { value: 50, label: "납품현황", menuOrder: 2, menuUse: true, menuParent: 48, },
                ]
            },
            {
                value: 51,
                label: "품질관리",
                menuOrder: 7,
                menuUse: true,
                menuParent: null,
                children: [
                    { value: 52, label: "불량통계", menuOrder: 1, menuUse: true, menuParent: 51, },
                    { value: 53, label: "초중종 검사 List", menuOrder: 2, menuUse: true, menuParent: 51, },
                    { value: 54, label: "작업표준서 관리", menuOrder: 3, menuUse: true, menuParent: 51, },
                    { value: 55, label: "변경점 정보 List", menuOrder: 4, menuUse: true, menuParent: 51, },
                ]
            },
            {
                value: 56,
                label: "설비금형기준관리",
                menuOrder: 8,
                menuUse: true,
                menuParent: null,
                children: [
                    { value: 57, label: "주변장치", menuOrder: 1, menuUse: true, menuParent: 56, },
                    { value: 58, label: "기계", menuOrder: 2, menuUse: true, menuParent: 56, },
                ]
            },
            {
                value: 59,
                label: "KPI모니터링",
                menuOrder: 9,
                menuUse: true,
                menuParent: null,
                children: [
                    { value: 60, label: "제조리드타임 (P)", menuOrder: 1, menuUse: true, menuParent: 59, },
                    { value: 61, label: "품질불량률 (Q)", menuOrder: 2, menuUse: true, menuParent: 59, },
                    { value: 62, label: "작업공수 (C)", menuOrder: 3, menuUse: true, menuParent: 59, },
                    { value: 63, label: "수주/납품 리드타임 (D)", menuOrder: 4, menuUse: true, menuParent: 59, },
                    { value: 64, label: "전력사용량 (E)", menuOrder: 5, menuUse: true, menuParent: 59, },
                    { value: 65, label: "설비가동률 (P)", menuOrder: 6, menuUse: true, menuParent: 59, },
                ]
            },
        ]
    }
];

const GridWrapper = styled.div`
  & *[role="grid"] {
    height: inherit;
    // --rdg-background-color: {(props) => (props.isDarkMode ? "white" : "none")};
    // --rdg-header-background-color: {(props) =>
    // props.isDarkMode ? "white" : "none"};
    // --rdg-color: {(props) => (props.isDarkMode ? "black" : "white")};
    // --rdg-row-hover-background-color: {(props) =>
    // props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;

const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
