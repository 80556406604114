import { mutateOperations } from "@/api/operations/useOperationsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DefaultApiOperationsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface OperationsCreateFormProps {
  code?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}

export function OperationsCreateForm(params: OperationsCreateFormProps) {
  const { code, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate: postMutate } = useMutation(
    (params: DefaultApiOperationsPostRequest) =>
      mutateOperations.create(params).mutationFn(params as DefaultApiOperationsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['operations']);
      },
    }
  );

  const form = useForm({
    initialValues: {
      code: code ?? "",
      name: "",
      siteCode: "",
      fromLocationCode: "",
      toLocationCode: "",
    },
  });

  const onSubmit = async () => {
    try {
      postMutate(
        {
          operationsGetRequest: {
            code: code ?? form.values.code,
            name: form.values.name,
            siteCode: form.values.siteCode,
            fromLocationCode: form.values.fromLocationCode,
            toLocationCode: form.values.toLocationCode,
          },
        },
        {
          onSuccess: (res) => {
            closeModal(form.values);
            if (res.status === 200) {
              customAlert(
                "공정 생성에 성공하였습니다.",
                "생성 성공",
                "green"
              )
            } else {
              customAlert(
                "공정 생성에 실패하였습니다.",
                "생성 실패",
                "red"
              )
            }
          },
          onError: (error: any) => {
            customAlert(handleErrorResponse({ error, errorContext: '공정코드' }), '공정 생성 실패', 'red')
          }
        }
      );
    } catch (e) {
      customAlert(
        "공정 생성에 실패하였습니다.",
        "생성 실패",
        "red"
      )
    }
  };

  return (
    <Box maw={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput
            withAsterisk
            label="공정코드"
            {...form.getInputProps("code")}
          />
          <TextInput
            withAsterisk
            label="공정명"
            {...form.getInputProps("name")}
          />
          <SitesAutoComplete
            withAsterisk
            label="사업장코드"
            // description="siteCode"
            width={"100%"}
            {...form.getInputProps("siteCode")}
          />
          <LocationAutoComplete
            withAsterisk
            label="자재 출고 로케이션코드"
            width={"100%"}
            {...form.getInputProps("fromLocationCode")}
          />
          <LocationAutoComplete
            withAsterisk
            label="재공품 입고 로케이션코드"
            width={"100%"}
            {...form.getInputProps("toLocationCode")}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "operations",
                values: form.values,
              })}
              onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
            >
              공정 생성
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
}

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
