import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { Space } from "@mantine/core";
import { AbstractItemsGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";

const DetailAbstractItem = Object.assign(DetailHeader, {
    Title: DetailTitle,
    Inform: DetailInformTitle,
    Field: DetailField,
    Label: DetailLabel,
    Link: DetailLink,
    LinkWrapper: DetailLinkWrapper,
    Badge: DetailBadge,
    Content: DetailContent,
});

interface AbstractItemDetailHeaderProps {
    data: AxiosResponse<AbstractItemsGet200Response, any[]> | undefined;
}

export const AbstractItemDetailHeader = (params: AbstractItemDetailHeaderProps) => {
    const { data } = params;

    const abstractItem = data?.data?.rows && data?.data?.rows[0];

    return (
        <DetailAbstractItem>
            <DetailAbstractItem.Title>
                {"[형태 상세]"}
            </DetailAbstractItem.Title>
            <Space h={8} />
            <DetailAbstractItem.Field>
                <DetailAbstractItem.Label>
                    {"형태 코드"}
                </DetailAbstractItem.Label>
                <DetailAbstractItem.Content>
                    {abstractItem?.code}
                </DetailAbstractItem.Content>
            </DetailAbstractItem.Field>
        </DetailAbstractItem>
    )
}