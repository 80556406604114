import { Flex, MultiSelect, MultiSelectProps, Text } from "@mantine/core";
import { forwardRef, useMemo, useState } from "react";

import { FKInfoLabel } from "@/features/ui/Base/List/CustomFilter/MultiField";
import { IconX } from "@tabler/icons-react";
import { useItemCategoriesMultiAutoComplete, useItemCategoriesMultiCode } from "./multiAuto-useGetItemCategoriesQuery";

interface ItemCategoryMultiProps extends React.ComponentPropsWithoutRef<"div"> {
    label: string;
    value: string;
}

export interface ItemCategoriesMultiAutoCompleteProps
    extends Partial<MultiSelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
    value: string[];
    onChange?: (e: string[]) => void; // (value: string[]) => React.Dispatch<React.SetStateAction<string>>;
    maxDropdownHeight?: number;
    width?: string;
}

export const ItemCategoriesMultiAutoComplete = (
    params: ItemCategoriesMultiAutoCompleteProps
) => {
    const {
        value: itemCategories,
        onChange,
        maxDropdownHeight,
        width,
        ...etcParams
    } = params;
    const [focused, setFocused] = useState<boolean>(true);
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const { data: options } = useItemCategoriesMultiAutoComplete(
        focused,
        searchKeyword
    );
    const { data: initialOptions } = useItemCategoriesMultiCode(
        !!searchKeyword,
        searchKeyword ?? null
    );

    const onChangeHandler = (e: string[]) => {
        onChange && onChange(e);
    };

    const SelectItemCategory = forwardRef<HTMLDivElement, ItemCategoryMultiProps>(
        ({ label: name, value: code, ...others }, ref) => (
            <div ref={ref} {...others}>
                <Flex direction="row" justify="space-between" align="center">
                    <Flex align={"center"}>
                        <Text>{name}</Text>
                        <Text fz="xs">(Color명: {code})</Text>
                    </Flex>
                </Flex>
            </div>
        )
    );

    // data prop에 들어가는 중복 제거된 data를 useMemo로 감싸기
    const filteredOptions = useMemo(() => {
        const combinedOptions = [
            ...(options ?? []),
            ...(initialOptions ?? []),
            ...(itemCategories.map((value) => ({ label: value, value })) ?? []),
        ];

        return combinedOptions.reduce(
            (unique: ItemCategoryMultiProps[], option: ItemCategoryMultiProps) =>
                unique.some((u) => u.value === option.value) ? unique : [...unique, option],
            []
        );
    }, [options, initialOptions, itemCategories]);

    return (
        <MultiSelect
            clearable
            value={itemCategories}
            data={filteredOptions}
            onChange={onChangeHandler}
            searchable
            itemComponent={SelectItemCategory}
            searchValue={searchKeyword}
            withinPortal
            onDropdownOpen={() => setFocused(true)}
            onDropdownClose={() => setFocused(false)}
            maxDropdownHeight={maxDropdownHeight ?? 150}
            onSearchChange={setSearchKeyword}
            nothingFound="No Options"
            rightSection={ItemCategoryInfo({
                ItemCategory: itemCategories,
                onChange: onChangeHandler,
            })}
            {...etcParams}
        />
    );
};

const ItemCategoryInfo = (params: {
    ItemCategory?: string[];
    onChange: (e: string[]) => void
}) => {
    const { ItemCategory, onChange } = params;

    const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
        e.stopPropagation();
        e.preventDefault();
        onChange([]);
    };

    return ItemCategory?.length ? (
        <FKInfoLabel>
            <IconX size="1rem" onClick={clearHandler} />
        </FKInfoLabel>
    ) : null;
};

