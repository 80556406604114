
import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { items } from "@/api/items/useItemQuery";
import { mutateLots } from "@/api/lots/useLotsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { LotMutationFromProps } from "@/features/stockPlan/form/stockPlanIncomingForm";
import customAlert from "@/features/ui/alert/alert";
import useGetLotsIdQuery from "@/features/ui/autoComplete/lot/select-useGetLotsQuery";
import { useForm } from "@mantine/form";
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem, DefaultApiLotsPostRequest, DefaultApiWmsIncomingPostRequest, PurchaseOrdersGet200ResponseRowsInner, WorkLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { EventHandler, useState } from "react";

export const useOrderIncomingLogic = (
    formatterProps : AuthSignupPost201ResponseEquipmentPurchaseOrderItem | undefined,
    purchaseOrder? : PurchaseOrdersGet200ResponseRowsInner
) => {
    
    const [newLot, setNewLot] = useState<LotMutationFromProps>();
    const queryClient = useQueryClient();
    const { closeModal } = useModal();
    const form = useForm<Partial<WorkLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInner>>({
        initialValues: {
            itemCode: formatterProps?.itemCode,
            locationCode: formatterProps?.targetLocationCode || purchaseOrder?.targetLocationCode,
            quantity: undefined,
            lotId: undefined,
            lotInfo: {
                name: undefined,
                expiration: undefined,
            },
            purchaseOrderItemId: formatterProps?.id,
        },
    });

    const { mutate: incomingMutate } = useMutation(
        (params: DefaultApiWmsIncomingPostRequest) =>
            mutateInventories.incoming(params).mutationFn(params as DefaultApiWmsIncomingPostRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["inventories"]);
                queryClient.invalidateQueries(["purchaseOrderItems"]);
                customAlert(
                    "남품에 성공하였습니다.",
                    "납품 성공",
                    "green"
                )
                closeModal(null);
            },
            onError: () => {
                customAlert(
                    "납품에 실패하였습니다.",
                    "납품 실패",
                    "red"
                )
            }
        }
    )

    const { mutate: postLotsMutate } = useMutation(
        (params: DefaultApiLotsPostRequest) =>
            mutateLots.create(params).mutationFn(params as DefaultApiLotsPostRequest | any),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries(["lots"]);
                form.setFieldValue("lotId", data?.data?.id);
                form.setFieldValue("lotInfo.name", data?.data?.name);
                form.setFieldValue("lotInfo.expiration", data?.data?.expiration);
                setNewLot(data?.data as LotMutationFromProps | undefined);
                alert("로트를 생성했습니다.");
            }
        }
    );

    const { data: itemData } = useQuery({...items.codeSearch({
        query: form.values.itemCode
            ? { $and: [{ code: { $eq: form.values.itemCode } }] }
            : "",
    }), enabled: !!form.values.itemCode});

    const { data: lotData } = useGetLotsIdQuery(
        Number(form.values?.lotId) || Number(newLot?.id),
        !!form.values?.lotId || !!newLot?.id
    );

    const onSubmit = () => {
        
        incomingMutate({
            workLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInner: {
                itemCode:
                    formatterProps?.itemCode ?? '',
                locationCode:
                    form.values.locationCode!,
                quantity: form.values.quantity as string,
                lotId: Number(newLot?.id ?? form.values.lotId),
                lotInfo: {
                    name: // 로트아이디 클릭시 데이터가 들어오지 않아서 lotData?.name을 사용
                        newLot?.name ?? lotData?.name,
                    expiration: // 로트아이디 클릭시 데이터가 들어오지 않아서 lotData?.expiration을 사용
                        newLot?.expiredAt ?? lotData?.expiration,
                },
                purchaseOrderItemId: formatterProps?.id,
                actionFrom: 'WEB'
            }
        });
    };
    
    const onAddNewLot: EventHandler<any> = (values) => {
        postLotsMutate({
            lotsGetRequest: {
                name: values.name,
                expiration: values.expiredAt,
                itemCode: values.itemCode,
            }
        });
    };

    return {
        form,
        onSubmit,
        onAddNewLot,
        newLot,
        itemData,
        lotData
    };
};
