import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { Button, Checkbox, Flex, NumberInput, Select, Table, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useState } from "react";

// Row 타입 정의
interface Row {
    id: number;
    code: string;
    productName: string;
    specification: string;
    location: string;
    lotNumber: number;
    serialCount: number;
    serialNumber: string;
    quantity: number;
    price: number;
    amount: number;
    vat: number;
    total: number;
    checked: boolean;
}

const ShipmentRequestWrap = {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    ActionHeader: ActionHeader,
    ActionButton: Button,
    Wrapper: Wrapper,
};

export const ShipmentRequestPage = () => {
    // useForm으로 폼 데이터 관리
    const form = useForm({
        initialValues: {
            rows: [] as Row[],
        },
    });

    // 전체 선택 여부 상태
    const [allChecked, setAllChecked] = useState(false);

    // 행 추가 핸들러
    const handleAddRow = () => {
        form.insertListItem('rows', {
            id: form.values.rows.length + 1,
            code: "",
            productName: "",
            specification: "",
            location: "",
            lotNumber: 0,
            serialCount: 0,
            serialNumber: "",
            quantity: 0,
            price: 0,
            amount: 0,
            vat: 0,
            total: 0,
            checked: false,
        });
    };

    // 행 삭제 핸들러
    const handleDeleteRow = (id: number) => {
        form.setFieldValue(
            'rows',
            form.values.rows.filter((row) => row.id !== id)
        );
    };

    // 전체 체크 핸들러
    const handleCheckAll = (checked: boolean) => {
        setAllChecked(checked);
        form.setFieldValue(
            'rows',
            form.values.rows.map((row) => ({ ...row, checked }))
        );
    };

    // 개별 체크 핸들러
    const handleCheckRow = (id: number, checked: boolean) => {
        form.setFieldValue(
            'rows',
            form.values.rows.map((row) => (row.id === id ? { ...row, checked } : row))
        );
        const allChecked = form.values.rows.every((row) => (row.id === id ? checked : row.checked));
        setAllChecked(allChecked);
    };

    return (
        <ShipmentRequestWrap.Wrapper>
            <ShipmentRequestWrap.Header>
                <ShipmentRequestWrap.HeaderTitle>입고 의뢰 등록</ShipmentRequestWrap.HeaderTitle>
                <ShipmentRequestWrap.HeaderSubTitle>
                    {"입고를 의뢰할 수 있는 페이지 입니다."}
                </ShipmentRequestWrap.HeaderSubTitle>
            </ShipmentRequestWrap.Header>
            <Flex w="100%" justify="space-between" gap="xs" align="flex-end">
                <Flex gap="xs">
                    <Select
                        label="거래처"
                        placeholder="거래처 선택"
                        data={["SIZL"]}
                    />
                    <DateInput
                        label="입고일"
                        placeholder="입고일 선택"
                        valueFormat="YYYY-MM-DD"
                    />
                </Flex>
                <Flex gap="xs">
                    <Button color="cyan" onClick={handleAddRow}>행 추가</Button>
                    <Button>선택된 행 저장</Button>
                </Flex>
            </Flex>
            <Flex w="100%" h="100%" direction="column" gap="xs" justify="space-between">
                <Table withBorder withColumnBorders bg="white">
                    <thead>
                        <tr>
                            <th>
                                <Checkbox
                                    checked={allChecked}
                                    onChange={(event) => handleCheckAll(event.currentTarget.checked)}
                                />
                            </th>
                            <th>입고번호</th>
                            <th>CODE</th>
                            <th>제품명</th>
                            <th>로케이션</th>
                            <th>LOT 번호</th>
                            <th>추가할 시리얼 수</th>
                            <th>Serial 번호</th>
                            <th>입고량</th>
                            <th>단가</th>
                            <th>금액</th>
                            <th>부가세</th>
                            <th>총 금액</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {form.values.rows.length ? form.values.rows.map((row, index) => (
                            <tr key={row.id}>
                                <td>
                                    <Checkbox
                                        checked={row.checked}
                                        onChange={(event) =>
                                            handleCheckRow(row.id, event.currentTarget.checked)
                                        }
                                    />
                                </td>
                                <td>{row.id}</td>
                                <td>
                                    <TextInput
                                        value={row.code}
                                        onChange={(event) =>
                                            form.setFieldValue(`rows.${index}.code`, event.currentTarget.value)
                                        }
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        value={row.productName}
                                        onChange={(event) =>
                                            form.setFieldValue(`rows.${index}.productName`, event.currentTarget.value)
                                        }
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        value={row.location}
                                        onChange={(event) =>
                                            form.setFieldValue(`rows.${index}.location`, event.currentTarget.value)
                                        }
                                    />
                                </td>
                                <td>
                                    <NumberInput
                                        value={row.lotNumber}
                                        min={0}
                                        onChange={(value) =>
                                            form.setFieldValue(`rows.${index}.lotNumber`, value || 0)
                                        }
                                    />
                                </td>
                                <td>
                                    <NumberInput
                                        value={row.serialCount}
                                        min={0}
                                        onChange={(value) =>
                                            form.setFieldValue(`rows.${index}.serialCount`, value || 0)
                                        }
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        value={row.serialNumber}
                                        onChange={(event) =>
                                            form.setFieldValue(`rows.${index}.serialNumber`, event.currentTarget.value)
                                        }
                                    />
                                </td>
                                <td>
                                    <NumberInput
                                        value={row.quantity}
                                        min={0}
                                        onChange={(value) =>
                                            form.setFieldValue(`rows.${index}.quantity`, value || 0)
                                        }
                                    />
                                </td>
                                <td>
                                    <NumberInput
                                        value={row.price}
                                        min={0}
                                        onChange={(value) =>
                                            form.setFieldValue(`rows.${index}.price`, value || 0)
                                        }
                                    />
                                </td>
                                <td>
                                    <NumberInput
                                        value={row.amount}
                                        min={0}
                                        onChange={(value) =>
                                            form.setFieldValue(`rows.${index}.amount`, value || 0)
                                        }
                                    />
                                </td>
                                <td>
                                    <NumberInput
                                        value={row.vat}
                                        min={0}
                                        onChange={(value) =>
                                            form.setFieldValue(`rows.${index}.vat`, value || 0)
                                        }
                                    />
                                </td>
                                <td>
                                    <NumberInput
                                        value={row.total}
                                        min={0}
                                        onChange={(value) =>
                                            form.setFieldValue(`rows.${index}.total`, value || 0)
                                        }
                                    />
                                </td>
                                <td>
                                    <Button color="red" compact onClick={() => handleDeleteRow(row.id)}>
                                        삭제
                                    </Button>
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td colSpan={15}><Text ta="center">데이터가 없습니다. 행을 추가해 주세요.</Text></td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Flex>
        </ShipmentRequestWrap.Wrapper>
    );
};