import {
    MRT_PaginationState,
    // createRow,
    type MRT_ColumnDef
} from 'mantine-react-table';
import { useMemo, useState } from 'react';

import { wipInventory } from '@/api/wipInventories/useWipInventoriesQuery';
import { LocationDetailForm } from '@/features/location/detail/LocationDetailForm';
import { useModal } from '@/features/modal/ModalStackManager';
import { DetailLink } from '@/features/ui/detail/DetailLink';
import { MantineReactCommonTable } from '@/features/ui/mantineTable';
import DatetimeUtil from '@/utils/dateTimeUtil';
import { Checkbox } from '@mantine/core';
import { WorkLogsWorkLogIdCancelEndPut200ResponseCancelProgressInner } from '@sizlcorp/sizl-api-document/dist/models';
import { useQuery } from "@tanstack/react-query";
import { ProductionPlanFormProps } from '../../view';

export interface WorkLogsGet200ResponseRowsInner {
    updaterUserCode: string | any;
    updatedAt: string | any;
    quantity: number | any;
    id: number | any;
    productionPlanId: number | any;
    locationCode: string | any;
    routingStep: {
        map(
            arg0: (step: any, index: any) => import("react/jsx-runtime").JSX.Element
        ): unknown;
        FULL01?: boolean;
        FULL02?: boolean;
        FULL03?: boolean;
        FULL04?: boolean;
    };
}

export const WorkInventoryTabs = ({ data }: ProductionPlanFormProps) => {

    const planId = data && data?.id;

    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 1,
        pageSize: 10,
    });

    const { data: wipInvetory } = useQuery(wipInventory.get({
        query: data ? { $and: [{ "productionPlanId": { "$eq": planId } }] } : '',
        pageSize: pagination.pageSize,
        page: pagination.pageIndex
    }));

    const rows: WorkLogsWorkLogIdCancelEndPut200ResponseCancelProgressInner[] =
        wipInvetory?.data.rows ?? [];

    const { openModal } = useModal();

    const columns = useMemo<MRT_ColumnDef<WorkLogsWorkLogIdCancelEndPut200ResponseCancelProgressInner>[]>(
        () => [
            {
                accessorFn: (row) => row.id,
                accessorKey: 'id',
                header: '생산 계획 번호',
                size: 80,
                enableColumnFilter: false,
                Cell: (rows) => {
                    return (
                        <div style={{ textAlign: 'right' }}>{rows.row._valuesCache.id}</div>
                    )
                }
            },
            {
                accessorFn: (row) =>
                    <DetailLink
                        onClick={() => openModal(
                            <LocationDetailForm locationCode={row.locationCode} />,
                            null,
                            '로케이션 상세'
                        )}
                        justify="flex-start"
                    >
                        {row.locationCode}
                    </DetailLink>,
                accessorKey: 'locationCode',
                header: '로케이션',
                size: 140,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
            },
            {
                accessorFn: (row) => row.routingStep,
                accessorKey: 'routingStep',
                header: '라우팅',
                size: 140,
                enableColumnFilter: false,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
                Cell: (row) => {
                    if (row?.row._valuesCache.routingStep && typeof row?.row._valuesCache.routingStep === 'object') {
                        return Object.entries(row?.row._valuesCache.routingStep).map(([key, checked], index) => {
                            return (
                                <Checkbox
                                    key={index}
                                    value={key}
                                    label={`${index + 1}. ${key} 라우팅`}
                                    checked={checked as boolean}
                                    readOnly
                                    mb={8}
                                />
                            );
                        });
                    }
                    return null;
                },
            },
            {
                accessorFn: (row) => row.quantity,
                accessorKey: 'quantity',
                header: '수량',
                size: 80,
                enableColumnFilter: false,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
                Cell: (rows) => {
                    return (
                        <div style={{ textAlign: 'right' }}>{rows.row._valuesCache.quantity}</div>
                    )
                }
            },
            {
                accessorFn: (row) => `${DatetimeUtil(row.updatedAt ?? '')} (${row.updaterUserCode})`,
                accessorKey: 'updatedAt',
                header: '최근 수정정보',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
            },
        ],
        [],
    );

    return (
        <MantineReactCommonTable
            data={rows}
            columns={columns}
            pagination={pagination}
            setPagination={setPagination}
            totalCount={wipInvetory?.data?.total}
            totalPages={wipInvetory?.data?.totalPages}
        />
    )
};


