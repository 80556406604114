import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    Text: Text,
    Flex: Flex,
});

export const BOMCreateForm = () => {
    const { closeModal } = useModal();

    const form = useForm({
        initialValues: {
            partnerCode: undefined,
            modelName: undefined,
            bomCode: undefined,
            itemName: undefined,
            spec: undefined,
            itemType: undefined,
            unitText: undefined,
            operationCode: undefined,
        }
    })

    const onSubmit = async () => {
        try {
            closeModal(form.values);

            customAlert("BOM 등록에 실패하였습니다.", '등록 실패', 'red')
        } catch (e) {
            customAlert(
                "BOM 등록에 실패하였습니다.",
                "등록 실패",
                "red"
            )
        }
    };

    return (
        <Create.Wrapper>
            <Create.Input
                withAsterisk
                label="협력사"
                placeholder="협력사"
                {...form.getInputProps("partnerCode")}
            />
            <Create.Input
                withAsterisk
                label="모델명"
                placeholder="모델명"
                {...form.getInputProps("modelName")}
            />
            <Create.Input
                withAsterisk
                label="BOM 코드"
                placeholder="BOM 코드"
                {...form.getInputProps("bomCode")}
            />
            <Create.Input
                withAsterisk
                label="품목명"
                placeholder="품목명"
                {...form.getInputProps("itemName")}
            />
            <Create.Input
                withAsterisk
                label="품목군"
                placeholder="품목군"
                {...form.getInputProps("itemType")}
            />
            <Create.Input
                withAsterisk
                label="단위"
                placeholder="단위"
                {...form.getInputProps("unitText")}
            />
            <Create.Input
                withAsterisk
                label="공정코드"
                placeholder="공정코드"
                {...form.getInputProps("operationCode")}
            />
            <Create.ButtonBox>
                <Create.Button color="gray" onClick={closeModal}>취소</Create.Button>
                <Create.Button
                    disabled={validateFormValues({
                        pageType: "bom",
                        values: form.values,
                    })}
                    onClick={onSubmit}
                >
                    BOM 생성
                </Create.Button>
            </Create.ButtonBox>
        </Create.Wrapper>
    )
};