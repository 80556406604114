import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";

import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { Button, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { DefaultApiWmsClosePostRequest, WmsClosePostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Close = Object.assign({}, FormMain, {
  Button: Button,
  Input: TextInput,
  Date: DateInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  ItemAutoComplete: ItemAutoComplete,
});

export const CloseForm = (params: { formatterProps: any }) => {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { mutate: closeMutate } = useMutation(
    (params: DefaultApiWmsClosePostRequest) =>
      mutateInventories.close(params).mutationFn(params as DefaultApiWmsClosePostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["inventories"]);
      }
    }
  )

  const form = useForm<Partial<WmsClosePostRequest>>({
    initialValues: {
      itemCode: formatterProps.itemCode,
      locationCode: formatterProps.locationCode,
      quantity: formatterProps.quantity,
      lotId: formatterProps.lotId,
    },
  });

  const onSubmit = () => {
    try {
      const { itemCode, locationCode, quantity } = form.values;
      if (!itemCode && !locationCode && !quantity) return;

      closeMutate(
        {
          wmsClosePostRequest: {
            itemCode:
              formatterProps.itemCode || (form.values.itemCode as string),
            locationCode:
              formatterProps.locationCode ||
              (form.values.locationCode as string),
            quantity: form.values.quantity as string,
            lotId: Number(formatterProps.lotId || form.values.lotId),
            actionFrom: 'WEB'
          },
        },
        {
          onSuccess: (res: { status: number }) => {
            closeModal(form.values);
            if (res.status === 200) {
              customAlert(
                "재고 마감에 성공하였습니다.",
                "재고 마감 성공",
                "green"
              )
            } else {
              customAlert(
                "재고 마감에 실패하였습니다.",
                "재고 마감 실패",
                "red"
              )
            }
          },
        }
      );
    } catch (e) {
      alert("실패");
    }
  };

  return (
    <>
      <Close.Wrapper>
        <Close.ItemAutoComplete
          disabled
          label="Grade"
          withAsterisk
          {...form.getInputProps("itemCode")}
          defaultValue={formatterProps?.itemCode}
        />
        <LocationAutoComplete
          disabled
          label="로케이션코드"
          withAsterisk
          {...form.getInputProps("locationCode")}
          defaultValue={formatterProps?.locationCode}
        />
        <Close.Input
          label="수량"
          withAsterisk
          type="number"
          {...form.getInputProps("quantity")}
          defaultValue={formatterProps?.quantity}
        />

        <Close.ButtonBox>
          <Close.Button color="gray" onClick={closeModal}>
            취소
          </Close.Button>
          <Close.Button onClick={onSubmit}>재고 마감</Close.Button>
        </Close.ButtonBox>
      </Close.Wrapper>
    </>
  );
};
