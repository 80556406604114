import { MasterInstance } from "@/instance/axios";
import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { MasterApiItemCategoriesGetRequest, MasterApiItemCategoriesItemCategoryIdDeleteRequest, MasterApiItemCategoriesItemCategoryIdPutRequest, MasterApiItemCategoriesPostRequest } from "@sizlcorp/sizl-api-document/dist/models";


export const itemCategories = createQueryKeys('itemCategories', {
    all: null,
    get: (params: MasterApiItemCategoriesGetRequest) => {
        // const queryParams = { ...params };
        const queryParams = { ...params, query: JSON.stringify({ "$and": params?.query }), sort: params.sort?.length ? params.sort : "-id", }; // 품목분류 조회 시 기본 정렬 표시

        return {
            queryKey: [queryParams, JSON.stringify(queryParams)],
            queryFn: () => MasterInstance.itemCategoriesGet(queryParams),
        }
    },
    // get: (params: MasterApiItemCategoriesGetRequest) => {
    //     const query = { ...params }; // params 객체를 복사합니다.

    //     if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
    //         query["query"] = JSON.stringify(params.query);
    //     }
    //     // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
    //     if (params.search) {
    //         query["search"] = params.search;
    //         query["searchFields"] = params.searchFields;
    //     }
    //     return {
    //         queryKey: [query, JSON.stringify(query.query)],
    //         queryFn: () => MasterInstance.itemCategoriesGet(query)
    //     }
    // },
    // query가 없음
    // find: (params: MasterApiItemsFindPostRequest) => {
    //     const query = { ...params }; // params 객체를 복사합니다.

    //     if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
    //         query["query"] = JSON.stringify(params.query);
    //     }
    //     // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
    //     if (params.search) {
    //         query["search"] = params.search;
    //         query["searchFields"] = params.searchFields;
    //     }
    //     return {
    //         queryKey: [query, JSON.stringify(query.query)],
    //         queryFn: () => MasterInstance.itemCategoriesFindPost(query)
    //     }
    // },
});

export const mutateItemCategories = createMutationKeys('itemCategories', {
    create: (params: MasterApiItemCategoriesPostRequest) => ({
        mutationKey: [params.itemCategoriesGetRequest],
        mutationFn: () => MasterInstance.itemCategoriesPost(params),
    }),
    update: (params: MasterApiItemCategoriesItemCategoryIdPutRequest) => ({
        mutationKey: [params.itemCategoriesGetRequest, params.itemCategoriesGetRequest],
        mutationFn: () => MasterInstance.itemCategoriesItemCategoryIdPut(params)
    }),
    delete: (params: MasterApiItemCategoriesItemCategoryIdDeleteRequest) => ({
        mutationKey: [params.itemCategoryId],
        mutationFn: () => MasterInstance.itemCategoriesItemCategoryIdDelete(params)
    }),
});

export const itemCategoriesQueryKeys = mergeQueryKeys(itemCategories, mutateItemCategories);