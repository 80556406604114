import { mutateDownTimeReasons } from "@/api/downtimeReasons/useDowntimeReasonsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import customAlert from "@/features/ui/alert/alert";
import { DragAndDropItemDataType, DragAndDropList } from "@/features/ui/dragAndDropList/DragAndDropList";
import { Button, Flex } from "@mantine/core";
import { DefaultApiDowntimeReasonsUpdateSeqPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconPlus, IconRecycle, IconReorder, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";

// 제네릭 타입을 포함하는 ActionHeaderProps 인터페이스 정의
interface ActionHeaderProps {
    createFn: () => void;
    createDataTitle?: string;
    deleteFn: () => void;
    downTimeReasonItems?: DragAndDropItemDataType[];
}

// 제네릭을 포함한 ES6 문법의 ActionHeader 컴포넌트
export const ActionHeader = ({ createFn, createDataTitle = "", deleteFn, downTimeReasonItems }: ActionHeaderProps) => {
    const { openModal, closeModal } = useModal();
    const { resetQueryStrings, refetch } = useStandardLayout();

    const { mutate: updateSeqMutate } = useMutation(
        (params: DefaultApiDowntimeReasonsUpdateSeqPostRequest) =>
            mutateDownTimeReasons.updateSeq(params).mutationFn(params as DefaultApiDowntimeReasonsUpdateSeqPostRequest | any),
        {
            onSuccess: () => {
                refetch();
                customAlert(
                    "비가동사유 순서 변경에 성공하였습니다.",
                    "변경 성공",
                    "green"
                );
                closeModal(null);
            },
            onError: () => {
                customAlert(
                    "비가동사유 순서 변경에 실패하였습니다.",
                    "변경 실패",
                    "red"
                )
            }
        }
    );

    const onSubmit = (items: DragAndDropItemDataType[]) => {
        updateSeqMutate({
            equipmentsUpdateSeqPostRequest: {
                code: items.map((item) => item.id),
                seq: items.map((_, index) => index + 1)
            }
        });
    }

    return (
        <>
            <Flex gap="md">
                <Button
                    leftIcon={<IconPlus />}
                    onClick={() => createFn()}
                >
                    {createDataTitle} 추가
                </Button>
                <Button
                    leftIcon={<IconReorder />}
                    color="cyan"
                    onClick={() =>
                        openModal(
                            <DragAndDropList
                                data={downTimeReasonItems}
                                onSubmit={onSubmit}
                            />,
                            null,
                            "비가동사유 순서설정",
                            true
                        )
                    }
                >
                    순서 변경
                </Button>
            </Flex>
            <Flex gap="md">
                <Button
                    rightIcon={<IconRecycle />}
                    color="teal"
                    onClick={() => resetQueryStrings()}
                >
                    검색값 초기화
                </Button>
                <Button
                    rightIcon={<IconTrash />}
                    color="red"
                    onClick={() => deleteFn()}
                >
                    선택된 행 삭제
                </Button>
            </Flex>
        </>
    );
};