import { itemTypeColor } from "@/constants/items";
import { AbstractItemDetailForm } from "@/features/abstractItem/form/detailForm";
import { ItemCategoryDetailForm } from "@/features/itemCategory/form/DetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { theme } from "@/styles/theme";
import { Badge, Checkbox, Flex, Space, Text, Title } from "@mantine/core";
import { ItemsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";


export const ItemView = (params: {
  itemData: Partial<ItemsGet200ResponseRowsInner>;
}) => {
  const {
    name,
    code,
    itemType,
    itemCategoryName,
    abstractItemCode,
    usesLotName,
    usesLotExpiration,
    spec,
    height,
    standardUph,
    depth,
    width,
    texture,
    unitText,
  } = params.itemData;

  const { t } = useTranslation();

  const { openModal } = useModal();

  //name, code, itemType, itemCategoryId, abstractItemId
  // TODO : 추후 props로 받는값으로 바꿔주기
  return (
    <Flex w="100%" p="md" bg={theme.colors?.gray?.[0]} direction="column" gap="0.5rem">
      <Title>품목 정보 확인</Title>
      <Space h="md" />
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          품목군
        </Text>
        <Badge color={itemTypeColor[itemType!]}>{t(itemType!)}</Badge>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          품목명
        </Text>
        <Text fz="sm" w="20rem">{name}</Text>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          Grade
        </Text>
        <Text fz="sm" w="20rem">{code}</Text>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          Color
        </Text>
        <DetailLink
          w="20rem"
          justify="flex-start"
          onClick={() => openModal(
            <ItemCategoryDetailForm ItemCategoryName={itemCategoryName} />,
            null,
            ""
          )}
        >
          {itemCategoryName}
        </DetailLink>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          형태
        </Text>
        <DetailLink
          w="20rem"
          justify="flex-start"
          onClick={() => openModal(
            <AbstractItemDetailForm AbstractItemCode={abstractItemCode} />,
            null,
            ""
          )}
        >
          {abstractItemCode}
        </DetailLink>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          단위
        </Text>
        <Text fz="sm" w="20rem">{unitText}</Text>
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          재고 LOT 관리 기준
        </Text>
        <Flex justify="space-between" align="center" gap="xl">
          <Checkbox label="이름"
            defaultChecked={usesLotName}
            disabled />
          <Checkbox
            label="유효기한"
            defaultChecked={usesLotExpiration}
            disabled
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
