import { mutateItemCategories } from "@/api/itemCategories/useItemCategoriesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { validateFormValues } from "@/utils/validation";
import { Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiItemCategoriesPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

interface ItemCategoryCreateFormProps {
  name?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}

export const ItemCategoryCreateForm = (params: ItemCategoryCreateFormProps) => {
  const { name, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate: createMutate } = useMutation(
    (params: MasterApiItemCategoriesPostRequest) =>
      mutateItemCategories.create(params).mutationFn(params as MasterApiItemCategoriesPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['itemCategories']);
      },
    }
  );

  const form = useForm({
    initialValues: {
      name: name ?? undefined,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate(
        {
          itemCategoriesGetRequest: {
            name: name ?? form.values.name!,
          },
        },
        {
          onSuccess: (res) => {
            closeModal(form.values);
            if (res.status === 200) {
              customAlert(
                "Color 생성에 성공하였습니다.",
                "생성에 성공",
                "green"
              )
            } else {
              customAlert(
                "Color 생성에 실패하였습니다.",
                "생성에 실패",
                "red"
              )
            }
          },
        }
      );
    } catch (e) {
      customAlert(
        "Color 생성에 실패하였습니다.",
        "생성에 실패",
        "red"
      )
    }
  };

  return (
    <Create.Wrapper>
      <Create.Input
        label="Color"
        withAsterisk
        {...form.getInputProps("name")}
      />
      <Create.ButtonBox>
        <Create.Button color="gray" onClick={closeModal}>
          취소
        </Create.Button>
        <Create.Button
          onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
          disabled={validateFormValues({
            pageType: "itemCategory",
            values: form.values,
          })}
        >
          Color 생성
        </Create.Button>
      </Create.ButtonBox>
    </Create.Wrapper>
  );
};
