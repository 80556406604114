import { abstractItems } from "@/api/abstractItems/useAbstractItemsQuery";
import { AbstractItemDetailHeader } from "@/features/abstractItem/header/DetailHeader";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { useQuery } from "@tanstack/react-query";

const Detail = Object.assign(Main, {
    Container: DetailContainer,
    Wrapper: DetailWrapper,
    Content: DetailContent,
})

interface AbstractItemDetailFormProps {
    AbstractItemCode: string | undefined;
}

export const AbstractItemDetailForm = (params: AbstractItemDetailFormProps) => {

    const { AbstractItemCode } = params;

    const { data: abstractItemData } = useQuery(abstractItems.get({
        query: { $and: [{ "code": { "$eq": AbstractItemCode } }] },
    }))

    return (
        <>
            <Detail.Container>
                <Detail.Wrapper>
                    <Detail.Content>
                        <AbstractItemDetailHeader data={abstractItemData} />
                    </Detail.Content>
                </Detail.Wrapper>
            </Detail.Container>
        </>
    )
}