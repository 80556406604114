import DefaultInstance from "@/instance/axios";
import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { DefaultApiRoutingOutsourcesFindPostRequest, DefaultApiRoutingOutsourcesGetRequest, DefaultApiRoutingOutsourcesPostRequest, DefaultApiRoutingOutsourcesRoutingOutsourceIdDeleteRequest, DefaultApiRoutingOutsourcesRoutingOutsourceIdGetRequest, DefaultApiRoutingOutsourcesRoutingOutsourceIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";


export const routingOutsources = createQueryKeys('routingOutsources', {
    all: null,
    get: (params: DefaultApiRoutingOutsourcesGetRequest) => {
        // const queryParams = { ...params };
        const queryParams = { ...params, query: JSON.stringify({ "$and": params?.query }), sort: params.sort?.length ? params.sort : "-id", }; // 외주처 조회 시 기본 정렬 표시

        return {
            queryKey: [queryParams, JSON.stringify(queryParams)],
            queryFn: () => DefaultInstance.routingOutsourcesGet(queryParams),
        }
    },
    // get: (params: DefaultApiRoutingOutsourcesGetRequest) => {
    //     const query = { ...params }; // params 객체를 복사합니다.

    //     if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
    //         query["query"] = JSON.stringify(params.query);
    //     }
    //     // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
    //     if (params.search) {
    //         query["search"] = params.search;
    //         query["searchFields"] = params.searchFields;
    //     }
    //     return {
    //         queryKey: [query, JSON.stringify(query.query)],
    //         queryFn: () => DefaultInstance.routingOutsourcesGet(query)
    //     }
    // },
    detail: (params: DefaultApiRoutingOutsourcesRoutingOutsourceIdGetRequest) => ({
        queryKey: [params.routingOutsourceId],
        queryFn: () => DefaultInstance.routingOutsourcesRoutingOutsourceIdGet(params)
    }),
    find: (params: DefaultApiRoutingOutsourcesFindPostRequest) => {
        const query = { ...params }; // params 객체를 복사합니다.

        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => DefaultInstance.routingOutsourcesFindPost(query)
        }
    },
})

export const mutateRoutingOutsources = createMutationKeys('routingOutsources', {
    create: (params: DefaultApiRoutingOutsourcesPostRequest) => ({
        mutationKey: [params.routingOutsourcesGetRequest],
        mutationFn: () => DefaultInstance.routingOutsourcesPost(params),
    }),
    update: (params: DefaultApiRoutingOutsourcesRoutingOutsourceIdPutRequest) => ({
        mutationKey: [params.routingOutsourceId, params.routingOutsourcesGetRequest],
        mutationFn: () => DefaultInstance.routingOutsourcesRoutingOutsourceIdPut(params)
    }),
    delete: (params: DefaultApiRoutingOutsourcesRoutingOutsourceIdDeleteRequest) => ({
        mutationKey: [params.routingOutsourceId],
        mutationFn: () => DefaultInstance.routingOutsourcesRoutingOutsourceIdDelete(params)
    }),

});

export const routingOutsourcesQueryKeys = mergeQueryKeys(routingOutsources, mutateRoutingOutsources);