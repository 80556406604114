import styled from "@emotion/styled";
import { Flex, Radio, Table } from "@mantine/core";

interface DeliveryTableProps {
    onChange: (e: number | undefined) => void;
    id : number | undefined;
    partnerCode: string | undefined;
    itemCode: string | undefined;
    quantity: string | undefined;
    locationCode: string | undefined;
    selectedRadio: null;
}
export const DeliveryTable = (params : DeliveryTableProps) => {
    const { id, partnerCode, itemCode, quantity, locationCode, selectedRadio, onChange } = params;
    return (
        <Table key={id} withBorder withColumnBorders w="40rem">
            <thead>
                <Tr>
                    <Th flex={0.5} className="small-column"></Th>
                    <Th flex={2}>협력사명</Th>
                    <Th flex={2}>품목명</Th>
                    <Th flex={1}>수량</Th>
                    <Th flex={2}>로케이션</Th>
                </Tr>
            </thead>
            <tbody>
                <Tr>
                    <Td flex={0.5} className="small-column">
                        <Flex
                            align="center"
                            justify="center"
                        >
                            <Radio
                                value={id}
                                checked={selectedRadio === id}
                                onChange={() => onChange(id)}
                            />
                        </Flex>
                    </Td>
                    <Td flex={2}>{partnerCode}</Td>
                    <Td flex={2}>{itemCode}</Td>
                    <Td flex={1} className="textRight">{quantity}</Td>
                    <Td flex={2}>{locationCode}</Td>
                </Tr>
            </tbody>
        </Table>
    )
}

const Th = styled.th<{ flex: number }>`
    padding: 0.5rem;
    text-align: center;
    flex : ${(props) => (props.flex ? props.flex : 1)};
`
const Tr = styled.tr`
    display: flex;
    flex-direction: row;
`

const Td = styled.td<{ flex: number }>`
    padding: 0.5rem;
    text-align: center;
    flex : ${(props) => (props.flex ? props.flex : 1)};

    &.textRight {
        text-align: right;
    }
`